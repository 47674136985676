<!-- 语法 -->
<template>
  <div>接口测试</div>
  <div class="a">
    <ul>
      <li @click="test()">执行数据库</li>
      <li @click="query()">查询</li>
      <li @click="bb()">添加</li>
      <li @click="cc()">更新</li>
      <li @click="dd()">删除</li>
      <li @click="ssq()">ssq</li>
      <li @click="fc3d()">fc3d</li>
      <li @click="qlc()">qlc</li>
      <li @click="cjdlt()">cjdlt</li>
      <li @click="qxc()">qxc</li>
      <li @click="pl35()">pl35</li>
      <li @click="pl5()">pl5</li>
      <li @click="klb()">klb</li>
    </ul>
    <div class="clear"></div>
  </div>

  <h2>文件上传</h2>
  <form id="form1" action="http://localhost:8000/upload/file" method="post" target="iframeContent"
    enctype="multipart/form-data">
    <input type="text" name="path" value='yclshoppic' hidden>
    <input type="file" name="file" style="border:1px solid red;width:150px">
    <input type="submit" value="上 传" @click="sub">
  </form>
  <iframe src="" frameborder="0" name="iframeContent" style="border:1px solid red;"></iframe>
  <img src="https://sd-ycl.net/yclshoppic/0.png" alt="" style="border:1px solid red;width:200px;height:200px;">

</template>

<script>
  import axios from 'axios'
  //import $ from 'jquery'
  //import '../js/font.js' //js文件引入，注意路径
  //import {myfun} from '../js/test.js' //js文件引入，注意路径
  //import Introduction from '@/views/Introduction.vue'
  //import api from '@/js/method'
  export default {
    name: '',
    components: {

    },
    data() { // 数据源
      return {
        i: 2023001,
      }
    },
    mounted() {

    },
    computed: {

    },
    methods: { // 函数体部分，js的主要逻辑控制  
      sub() {

      },
      inster(obj) {
        axios({
          method: "post",
          //url: "https://sd-ycl.com/lot/f2",
          //url: "http://localhost:8088/lottery/mytask/prize",
          url: "http://localhost:8088/lottery/demo/update",
          data: obj,
          //params:{tab:"fc3d",pageNum:2,pageSize:10}
        }).then(
          (res) => {
            if (res.data == "success") {
              console.log(res.data)
              this.test()
            }
          })
      },
      test() {
        var obj = {
          "tab": "qxc",
          "zd": "issueno",
          "value": this.i + ""
        }
        axios({
          method: "post",
          url: "http://localhost:8088/lottery/demo/find",
          data: obj
        }).then(
          (res) => {
            var x = res.data
            var red = x[0].number.split(' ')
            //var  lan = x[0].refernumber.split(',')
            console.log(this.i)
            obj = {
              "tab": "qxc",
              "zd": "issueno",
              "value": x[0].issueno,
              "issueno": x[0].issueno,
              "caipiaoid": 15,
              "name": "七星彩",
              "number": (red[0] + " " + red[1] + " " + red[2] + " " + red[3] + " " + red[4] + " " + red[5]),//+" "+red[6]
              "refernumber": red[6],//(lan[0]+" "+lan[1]),
              "opendate": x[0].opendate,
              // "prize":"",//JSON.stringify(obj)
            }

            if (x[0].issueno != null) {
              this.i++
              this.inster(obj)
            } else {
              console.log("aaaaa")
            }
          }
        )
      },

      query() {//查询
        //var array = ['000', '84521']
        // var yclshopUser = {
        // userpid: '000',
        //username: '李四',
        // userpassword: 'xiu',
        // userold: '33',
        // usersex: 1,
        // userphone: '18866666666',
        // usercity: '山东',
        // userdate: '20230822',
        // useraddress: '中国台湾',
        // userlevel: '111',
        // userbalance: '2222',
        // userintegral: '333333',
        // userstate: '4444444',
        // zd: 'userpid',
        // value: '3',
        // values: array,
        //}F

        // var yclshopPdt = {
        //   pdtname: '单反',
        //   pdtbrand: 'Sony',
        //   pdtnumber: '32145',
        // pdtmodel: '{"age":100,"name":"qiao"}',
        // pdtclass: '{"分类":"家电","分类1":"数码"}',
        // pdtlabel: '标签222',
        // pdturl: '{"url":"1222","url2":"22222"}',
        // pdtpromotion: '{"促销":"打折","促销1":"秒杀"}',
        // pdtmoney: '单价',
        // pdtshopmoney: '活动价',
        // pdtinventory: '库存',
        // pdtsold: '已售',
        //   zd: 'pdtnumber',
        //   value: '1',
        //   values: array,
        // }

        // var yclshopOrde = {
        //   ordepid: '2003',
        //   ordeshopname: '手机',
        //   ordenumber: '我的',
        //   ordebrand: '修改',
        // ordemodel: '4334',
        // ordeurl: '地地',
        // ordepromotion: '113331',
        // ordetime: '在',
        // ordepaymenttime: '33422',
        // ordestate: '442564',
        // ordepaymentmethod: '逼自己一把',
        // ordepaymentamount: '66',
        // ordeshopnumber: '777',
        // ordeshopmoney: '666',
        // ordemoney: '555',
        // ordeusename: '444',
        // ordeusephone: '333',
        // ordeuseaddress: '777',
        // ordedelivery: '777',
        // ordeafter: '666',
        //   zd:'ordepid',
        //   value:'2003',
        //   values:array,
        // }

        // var yclshopField = {
        //   zd: 'ordenumber',
        //   value: '哈哈哈,的要在'
        // }
        // var EntieyFiled={
        //   templateCode:'SMS_193021464',
        //   phoneNumber:18853666630
        // }
        axios({
          method: "post",
          //url: 'http://localhost:8000/lottery/demo/findj',
          //url: 'http://localhost:8000/yclshopPdtController/findmm',
          //url: 'http://localhost:8000/SMS/send',
          url: 'http://localhost:8000/upload',
          //data: EntieyFiled
        }).then(res => {
          console.log(res.data)
          //console.log(JSON.parse(res.data[0].pdtmodel).friends.name )
          // var arr0 = []
          // var x = res.data
          //  console.log(x)
          // var data = x.klbfind2
          // // console.log(data)
          // for (var i = 0; i < data.length; i++) {
          //   var arr = []
          //   var openCode = 0
          //   var max = 0
          //   var count = 0
          //   var rownum = 100000
          //   for (var a = 0; a < data[i].length; a++) {
          //     arr.push(data[i][a].match(/rownum=(\S*), /)[1]),
          //       openCode = data[i][a].match(/openCode=(\S*), /)[1]
          //     count = data[i][a].match(/count=(\S*)}/)[1]
          //   }
          //   arr.push(count)
          //   arr.sort(function (a, b) {
          //     return a - b;
          //   });
          //   for (var b = 0; b < arr.length; b++) {
          //     if (rownum > parseInt(arr[b])) {
          //       rownum = parseInt(arr[b]);
          //     }
          //   }
          //   if (arr.length != 1) {
          //     for (var c = 0; c < arr.length - 1; c++) {
          //       if (max < (parseInt(arr[c + 1]) - parseInt(arr[c]))) {
          //         max = parseInt(arr[c + 1]) - parseInt(arr[c])
          //       }
          //     }
          //   } else {
          //     max = parseInt(arr[0])
          //   }
          //   var obj = {
          //     openCode: openCode,
          //     number: rownum == count ? 0 : arr.length - 1,
          //     rownum: rownum,
          //     max: max,
          //     count: parseInt(count),
          //   }
          //   arr0.push(obj)
          // }
          // console.log(arr0)
        }).catch(error => {
          console.info(error);
        });
      },

    },
    props: {
    }
  }
</script>

<style scoped>
  /* @import './swiper.css'; */
  /* *{
  border:1px solid red;
} */
  a {
    width: 100%;
  }

  ul li {
    float: left;
    width: 100px;
    background-color: rgb(197, 198, 199);
  }

  .clear {
    clear: both;
  }
</style>